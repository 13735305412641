import {Request} from "../utils/requests"
import { requestPermission, messaging } from "../utils/fcm"

const actions = {

    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    updateSidebarWidth({ commit }, width) {
      commit('UPDATE_SIDEBAR_WIDTH', width);
    },
    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY');
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val);
    },
    updateUserRole({ commit }, val) {
      commit('UPDATE_USER_ROLE', val);
    },
    updateWindowWidth({ commit }, width) {
      commit('UPDATE_WINDOW_WIDTH', width);
    },
    updateSiteTitle({ commit }, title) {
      commit('UPDATE_SITE_TITLE', title);
    },


    // ////////////////////////////////////////////
    // COMPONENT
    // ////////////////////////////////////////////

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    //  The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },
    updateActiveEntity({ commit }, entity){
      commit('UPDATE_ACTIVE_ENTITY', entity)
    },
    updateActiveWorkSheets({ commit }, worksheets){
      commit('UPDATE_ACTIVE_WORK_SHEETS', worksheets)
    },
    updateActiveMenuSection({ commit }, section_name){
      commit('UPDATE_ACTIVE_MENU_SECTION', section_name)
    },
    loadActiveEntity( {commit} ){
      let entity = localStorage.getItem('active_entity')
      if(entity){
        entity = JSON.parse(entity)
        commit('UPDATE_ACTIVE_ENTITY', entity)
      }
    },
    setEntityLoadRedirect({commit}, path) {
      commit('SET_ENTITY_LOAD_REDIRECT', path)
    },
    updateConnectivityStatus({commit}, status) {
      commit('SET_CONNECTIVITY_STATUS', status)
    },
    updateFCMProgress({commit}, progress) {
      commit('SET_FCM_PROGRESS', progress)
    },
    updateServiceWorker({commit}, registration){
      commit('SET_SERVICE_WORKER', registration)
    },
    updateServerVersion({commit}, version){
      commit('SET_SERVER_VERSION', version)
    },
    sendFCMToken({state}, token) {
      Request(null, 'POST', state.apiendpoints.notification_token_fcm_subscribe, null, {"token": token}).then(
        res => {
          console.log("Notification Subscription Successful")
        },
        err => {
          console.log("Notification Subscription Failed")
          throw err
        }
      )
    },
    firebaseRegistered({ state }, vueInstance) {
      if (Notification.permission != 'granted') {
        vueInstance.$vs.dialog({
          type: 'confirm',
          color: 'primary',
          title: `Subscribe to Notifications`,
          text: 'We need your permissions to send notifications for various things like Compliances Due, Upcoming Compliance and Compliances Marked, etc. Please click allow here and then click allow in your browser to grant permissions!',
          accept: () => requestPermission(),
          cancel: () => null,
          acceptText: "Allow",
          cancelText: "Cancel"
        })
      } else {
        requestPermission()
      }
    }
}

export default actions
